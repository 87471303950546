/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable multiline-ternary */
import React from 'react';
import { ToastContainer } from 'react-toastify';

import {
  CountryApolloClient,
  LogisticCompanyApolloClient,
  LogisticTypeApolloClient,
  ProductApolloClient,
  ProductCategoryApolloClient,
  RecyclingProcessApolloClient,
} from '../../api';
import { Config } from '../../components';
import { HeaderComponent } from '../../components/HeaderComponent';
import { AdminPanelStartDialogState } from './contracts';
import { CountryComponent } from './CountryComponent';
import { LogisticCompanyComponent } from './LogisticCompanyComponent';
import { LogisticTypeComponent } from './LogisticTypeComponent';
import { ProductCategoryComponent } from './ProductCategoryComponent';
import { RecyclingProcessComponent } from './RecyclingProcessComponent/RecyclingProcessComponent';
import './AdminPanelStartDialog.css';
import { StartDialogProps } from '../../components/DialogRenderer/StartDialogRenderer/contracts';
export class AdminPanelStartDialog extends React.Component<StartDialogProps, AdminPanelStartDialogState> {

  private recyclingProcessApolloClient: RecyclingProcessApolloClient;
  private countryApolloClient: CountryApolloClient;
  private logisticTypeApolloClient: LogisticTypeApolloClient;
  private productCategoryApolloClient: ProductCategoryApolloClient;
  private logisticCompanyApolloClient: LogisticCompanyApolloClient;
  private productApolloClient: ProductApolloClient;
  private config: Config;

  constructor(props: StartDialogProps) {
    super(props);

    this.logisticTypeApolloClient = new LogisticTypeApolloClient(props.identity.token, props.config as Config);
    this.countryApolloClient = new CountryApolloClient(props.identity.token, props.config as Config);
    this.recyclingProcessApolloClient = new RecyclingProcessApolloClient(props.identity.token, props.config as Config);
    this.productCategoryApolloClient = new ProductCategoryApolloClient(props.identity.token, props.config as Config);
    this.logisticCompanyApolloClient = new LogisticCompanyApolloClient(props.identity.token, props.config as Config);
    this.productApolloClient = new ProductApolloClient(props.identity.token, props.config as Config);
    this.config = props.config as Config;
    this.state = {
      selectedTab: 'RecyclingProcess',
    };
  }

  public render(): JSX.Element {

    return (
      <>
        <HeaderComponent config={this.config as Config} />
        <div className='admin-panel flex flex-col justify-center items-center'>
          <nav className='admin-panel_navigation flex flex-row'>
            <button className={this.state.selectedTab === 'RecyclingProcess' ? 'navigation_tab isSelectedTab' : 'navigation_tab'} onClick={() => this.setState({ selectedTab: 'RecyclingProcess' })}>Recycling-Processes</button>
            <button className={this.state.selectedTab === 'Country' ? 'navigation_tab isSelectedTab' : 'navigation_tab'} onClick={() => this.setState({ selectedTab: 'Country' })}>Countries</button>
            <button className={this.state.selectedTab === 'Product Category' ? 'navigation_tab isSelectedTab' : 'navigation_tab'} onClick={() => this.setState({ selectedTab: 'Product Category' })}>Product Categories</button>
            <button className={this.state.selectedTab === 'Logistic Company' ? 'navigation_tab isSelectedTab' : 'navigation_tab'} onClick={() => this.setState({ selectedTab: 'Logistic Company' })}>Logistic Companies</button>
            <button className={this.state.selectedTab === 'Logistic Type' ? 'navigation_tab isSelectedTab' : 'navigation_tab'} onClick={() => this.setState({ selectedTab: 'Logistic Type' })}>Logistic Types</button>
          </nav>
          <div className='admin-panel_main-page mt-4 flex flex-col'>
            {this.state.selectedTab === 'RecyclingProcess' ? <RecyclingProcessComponent
              closeStartDialog={this.props.closeStartDialog}
              identity={this.props.identity}
              language='en'
              openStartDialog={this.props.openStartDialog}
              payload={this.props.payload}
              portalLocation={this.props.portalLocation}
              startDialogConfiguration={this.props.startDialogConfiguration}
              startProcess={this.props.startProcess}
              recyclingProcessApolloClient={this.recyclingProcessApolloClient}
              productApolloClient={this.productApolloClient}
              config={this.config}
            /> : ''}
            {this.state.selectedTab === 'Country' ? <CountryComponent countryApolloClient={this.countryApolloClient} logisticTypeApolloClient={this.logisticTypeApolloClient} /> : ''}
            {this.state.selectedTab === 'Product Category' ? <ProductCategoryComponent productCategoryApolloClient={this.productCategoryApolloClient} /> : ''}
            {this.state.selectedTab === 'Logistic Company' ? <LogisticCompanyComponent logisticCompanyApolloClient={this.logisticCompanyApolloClient} /> : ''}
            {this.state.selectedTab === 'Logistic Type' ? <LogisticTypeComponent logisticTypeApolloClient={this.logisticTypeApolloClient} /> : ''}
          </div>
          <ToastContainer position='bottom-right' />
        </div>
      </>
    );
  }

}
