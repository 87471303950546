import React, { Component } from 'react';

import { CustomFormsRenderer, StartDialogRenderer } from './components/DialogRenderer';
import './custom.css';

import {
  CreateManualLabelDialog,
  IncomingPackageCustomForm,
  SuccessPageCustomForm,
  TesMailDeliveredCustomForm,
  TesLocalCollectionEmailDeliveredCustomForm,
  CustomerMailDeliveredCustomForm,
} from './features';
import { ProductMappingCustomForm } from './features/AdminPanelStartDialog/ProductMappingCustomForm';

export default class App extends Component {
  static displayName = App.name;


  render(): JSX.Element {
    return (
      <div style={{ height: '100%' }}>
        <CustomFormsRenderer
          components={{
            CreateManualLabelDialog: CreateManualLabelDialog,
            SuccessPageCustomForm: SuccessPageCustomForm,
            TesMailDeliveredCustomForm: TesMailDeliveredCustomForm,
            IncomingPackageCustomForm: IncomingPackageCustomForm,
            TesLocalCollectionEmailDeliveredCustomForm: TesLocalCollectionEmailDeliveredCustomForm,
            CustomerMailDeliveredCustomForm: CustomerMailDeliveredCustomForm,
            ProductMappingCustomForm: ProductMappingCustomForm,
          }}
        />
        <StartDialogRenderer />
      </div>
    );
  }
}
