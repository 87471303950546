import { gql } from '@apollo/client';

export const getAllProductsQuery = gql`
{
  allProducts {
    id,
    lob,
    family,
    model,
    productCategory {
      id,
      name,
      packageWeight,
      isOther,
      invoiceValue,
      commodityCode
    }
  }
}
`;
