import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Config } from '../../components';
import { ErrorHandler } from '../../util';
import { getAllProductsQuery, GetAllProductsWithoutCategoryQuery } from './Queries';
import { Product } from '../../contracts/Product';

export class ProductApolloClient {

  private apolloClient: ApolloClient<NormalizedCacheObject>;

  constructor(identityToken: string, config: Config) {
    const httpLink = createHttpLink({
      uri: config.webAPIConfig.graphQlHttpApiUrl,
    });

    const authLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        authorization: identityToken ? `Bearer ${identityToken}` : '',
      },
    }));

    this.apolloClient = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });
  }

  public async getAllProducts(): Promise<Array<Product>> {
    try {
      const queryResult = await this.apolloClient.query({
        query: getAllProductsQuery,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });

      return queryResult.data.allProducts;
    } catch (error) {
      ErrorHandler.handleError(error);
      return [];
    }
  }

  public async getAllProductsWithoutCategory(): Promise<Array<Product>> {
    try {
      const queryResult = await this.apolloClient.query({
        query: GetAllProductsWithoutCategoryQuery(),
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      });

      return queryResult.data.allProductsWithoutCategory;
    } catch (error) {
      ErrorHandler.handleError(error);
      return [];
    }
  }
}
