/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import * as XLSX from 'xlsx';

import { HeaderComponent } from '../../../components/HeaderComponent';
import { CustomFormProps } from '../../../components/DialogRenderer/CustomFormsRenderer/contracts';
import { ProductApolloClient, ProductCategoryApolloClient } from '../../../api';
import { Config } from '../../../components';
import { ProductMappingCustomFormState } from './contracts';
import { empty } from '@apollo/client';
import { ro } from 'date-fns/locale';

export class ProductMappingCustomForm extends React.Component<CustomFormProps, ProductMappingCustomFormState> {

  private productApolloClient: ProductApolloClient;
  private productCategoryApolloClient: ProductCategoryApolloClient;

  constructor(props: CustomFormProps) {
    super(props);

    this.state = {
      productsWithoutCategories: [],
      productCategories: [],
      jsonData: null,
    };

    this.productApolloClient = new ProductApolloClient(props.identity.token, props.config as Config);
    this.productCategoryApolloClient = new ProductCategoryApolloClient(props.identity.token, props.config as Config);
  }

  async componentDidMount(): Promise<void> {
    const productsWithoutCategory = await this.productApolloClient.getAllProductsWithoutCategory();
    const productCategories = await this.productCategoryApolloClient.getAllProductCategories();

    this.setState({
      productsWithoutCategories: productsWithoutCategory,
      productCategories: productCategories,
    });

  }

  private async _handleFile(e: any): Promise<void> {
    const file = e.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
      header: ['GlobalID', 'LOB', 'Family', 'Model', 'ProductCategory'],
      defval: 'null',
    });

    this.setState({
      jsonData: jsonData,
    });

  }

  public render(): JSX.Element {
    return (
      <div>
        <HeaderComponent config={this.props.config} />
        <div className='flex justify-center items-center'>
          <div className='flex flex-col justify-center items-center w-max'>
            <div className='flex flex-col w-max gap-4 justify-center mt-4 p-4 border-black border-2 shadow-sm rounded-md'>
              <h2><b>Product Mapping</b></h2>
              <p>Please upload a mapping file:</p>
              <input
                type='file'
                onInput={(e) => this._handleFile(e)}
              />
              <button className='bg-blue-500 disabled:bg-gray-700 enabled:hover:bg-blue-400 text-white font-bold py-2 border-b-4 enabled:border-blue-700 enabled:hover:border-blue-500 rounded-xl' onClick={() => this.props.finishUserTask({ data: this.state.jsonData })}>Submit</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
