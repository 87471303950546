/* eslint-disable @typescript-eslint/naming-convention */
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

const loadTranslation = async (lang: string): Promise<any> => {
  const response = await fetch(`/translation/${lang}/translation.json`);
  return response.json();
};

const loadResources = async (): Promise<any> => {
  const [de_DE, en_DE, en_US, en_GB, en_IE, pt_PT, pl_PL, tr_TR, fr_FR, nl_NL, it_IT, ar_AE, ar_SA, de_BE, fr_BE, nl_BE, es_ES] = await Promise.all([
    loadTranslation('de-DE'),
    loadTranslation('en-DE'),
    loadTranslation('en-US'),
    loadTranslation('en-GB'),
    loadTranslation('en-IE'),
    loadTranslation('pt-PT'),
    loadTranslation('pl-PL'),
    loadTranslation('tr-TR'),
    loadTranslation('fr-FR'),
    loadTranslation('nl-NL'),
    loadTranslation('it-IT'),
    loadTranslation('ar-AE'),
    loadTranslation('ar-SA'),
    loadTranslation('de-BE'),
    loadTranslation('fr-BE'),
    loadTranslation('nl-BE'),
    loadTranslation('es-ES'),
  ]);

  return {
    'de-DE': {
      translation: de_DE,
    },
    'de-BE': {
      translation: de_BE,
    },
    'en-DE': {
      translation: en_DE,
    },
    'en-US': {
      translation: en_US,
    },
    'es-ES': {
      translation: es_ES,
    },
    'en-GB': {
      translation: en_GB,
    },
    'en-IE': {
      translation: en_IE,
    },
    'pt-PT': {
      translation: pt_PT,
    },
    'pl-PL': {
      translation: pl_PL,
    },
    'tr-TR': {
      translation: tr_TR,
    },
    'fr-FR': {
      translation: fr_FR,
    },
    'fr-BE': {
      translation: fr_BE,
    },
    'nl-NL': {
      translation: nl_NL,
    },
    'nl-BE': {
      translation: nl_BE,
    },
    'it-IT': {
      translation: it_IT,
    },
    'ar-AE': {
      translation: ar_AE,
    },
    'ar-SA': {
      translation: ar_SA,
    },
  };
};

i18next.use(initReactI18next).init({
  fallbackLng: 'en-US',
});

(async () => {
  const resources = await loadResources();

  i18next
    .use(initReactI18next)
    .init({
      resources,
      fallbackLng: 'en-US',
    });
})();


export default i18next;
