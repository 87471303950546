import { DocumentNode, gql } from '@apollo/client';

export function GetAllProductsWithoutCategoryQuery(): DocumentNode {
  return gql`
    query {
      allProductsWithoutCategory {
        id,
        lob,
        family,
        model,
        productCategory {
          id,
          name,
          packageWeight,
          isOther,
          invoiceValue,
          commodityCode
        }
      }
    }
  `;
}
