import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Identity } from '@atlas-engine/atlas_engine_client';
import { StartDialogDisplayedCallback, StartDialogService } from '@atlas-engine-contrib/atlas-ui_sdk';

import { AdminPanelStartDialog, RecyclingPlatformStartDialog } from '../../../features';
import { ConfigService } from '../../../api/config';
import {
  StartDialogComponentDict,
  StartDialogConfiguration,
  StartDialogProps,
  StartDialogRendererProps,
  StartDialogRendererState,
} from './contracts';
import { RecyclingProcessComponent } from '../../../features/AdminPanelStartDialog/RecyclingProcessComponent/RecyclingProcessComponent';

class StartDialogRenderer extends React.Component<StartDialogRendererProps & WithTranslation, StartDialogRendererState> {

  private startDialogService: StartDialogService | null = null;
  private readonly configService = new ConfigService();

  constructor(props: StartDialogRendererProps & WithTranslation) {
    super(props);

    this.configService.getConfig().then((config) => {
      this.setState({ config: config });
    });

    this.state = {
      targetComponent: null,
      targetIdentity: null,
      targetPayload: null,
      targetStartDialogConfiguration: null,
      currentLanguage: 'de',
      portalLocation: undefined,
    };
  }

  private components: StartDialogComponentDict = {
    RecyclingPlatformStartDialog: RecyclingPlatformStartDialog,
    AdminPanelStartDialog: AdminPanelStartDialog,
  };

  public componentDidMount(): void {
    this.startDialogService = new StartDialogService();
    this.startDialogService.onStartDialogDisplayed(this.displayStartDialog as StartDialogDisplayedCallback);

    this.components = {
      ...this.components,
      ...this.props.components,
    };
  }

  public componentWillUnmount(): void {
    if (!this.startDialogService) {
      return;
    }
    this.startDialogService.destroy();
  }

  private displayStartDialog = (config: StartDialogConfiguration, identity: Identity, payload: any): void => {
    const component = this.components[config.id];
    if (!component) {
      throw new Error(`No component found for usertask ${JSON.stringify(config)}`);
    }

    this.setState({
      targetComponent: component,
      targetIdentity: identity,
      targetStartDialogConfiguration: config,
      targetPayload: payload,
    });
  };

  public render(): JSX.Element | null {
    if (!this.state.targetComponent || !this.state.targetStartDialogConfiguration || !this.state.targetIdentity || !this.state.config) {
      return null;
    }

    const props: StartDialogProps = {
      language: 'de',
      portalLocation: this.startDialogService?.getLocation(),
      closeStartDialog: (): void => this.startDialogService?.closeStartDialog(),
      openStartDialog: (startDialogId: string): void => this.startDialogService?.openStartDialog(startDialogId),
      identity: this.state.targetIdentity,
      startDialogConfiguration: this.state.targetStartDialogConfiguration,
      startProcess: (processModelId, payload?, startEventId?): void => {
        this.startDialogService?.startProcess(processModelId, payload, startEventId);
      },
      config: this.state.config,
      payload: this.state.targetPayload,
    };

    const componentInstance = React.createElement(this.state.targetComponent, props);

    return componentInstance;
  }

}

export default withTranslation()(StartDialogRenderer);
